





























import IDownloadService from '@/data/DownloadService.Types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ApprovedData } from '@/data/DownloadService.Types';
import DownloadsTable from '@/components/DownloadsTable.vue';
import $ from 'jquery';

@Component({
    components: {
        DownloadsTable
    }
})
export default class Downloads extends Vue {
    public AllSelected: boolean = false;

    @Prop({
        required: true
    })
    public DownloadService!: IDownloadService;

    @Prop({
        required: true
    })
    public Token!: string;

    @Prop({
        required: true
    })
    public JSESSION!: string;

    @Prop({
        default: ''
    })
    public ApplicationDownloadURL!: string;

    @Prop({
        default: ''
    })
    public CentralServerURL!: string;

    private Data: ApprovedData[] = [];

    private SelectedData: ApprovedData[] = [];

    private get DownloadAllDisabled(): boolean {
        return this.SelectedData.length === 0;
    }

    public mounted(): void {
        if (!this.Token || !this.JSESSION) {
            this.$router.push('/Login');
        }
        this.DownloadService.GetDownloadItems(this.Token, this.JSESSION)
            .then((manifest) => {
                if (!manifest.ApprovalId) {
                    this.$router.push('/Login');
                }
                this.Data = manifest.ApprovedData;
            });
    }

    public OnDownloadSelectedWithApp(): void {
        window.location.href = `${this.CentralServerURL.replace('https', 'xdts')}/xapi/data/access/manifest?bearer=${this.Token}&jsessionid=${this.JSESSION}`;
    }

    public OnDownloadSingle(item: ApprovedData, button: any): void {
        this.DownloadService.DownloadItem(item, () => {
            button.attr('disabled', true);
            $('span.v-btn__content', button).html('<i class="fa fa-hourglass" aria-hidden="true"></i> Downloading...');
        }, () => {
            button.removeAttr('disabled');
            $('span.v-btn__content', button).html('<i class="fa fa-download" aria-hidden="true"></i> Download Now');
        });
    }
}
