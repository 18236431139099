















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ApprovedData } from '@/data/DownloadService.Types';
import * as prettyBytes from 'pretty-bytes';
import $ from 'jquery';

@Component({ 
    name: 'downloads-table'
})
export default class DownloadsTable extends Vue {
    public HelperFunctions = {
        prettyBytes
    };

    @Prop({
        required: true
    })
    private items!: ApprovedData[];

    @Prop({
        default: true
    })
    private IsDataLoading!: boolean;

    private OnDownload(item: ApprovedData, event: Event): void {
        let $button = $(event.target as any);
        if ($button.prop('nodeName') !== 'BUTTON') {
            $button = $button.parents('button');
        }
        this.$emit('DownloadSingle', item, $button);
    }

    private FormatDate(date: Date): string {
        const dd = this.PadTime(date.getDate());
        const MM = this.PadTime(date.getMonth() + 1);
        const yyyy = date.getFullYear();
        const hours = date.getHours() > 12 ? this.PadTime(date.getHours() - 12) : this.PadTime(date.getHours());
        const amPm = date.getHours() > 11 ? 'PM' : 'AM';
        const seconds = this.PadTime(date.getSeconds());
        const minutes = this.PadTime(date.getMinutes());
        const time = `${hours}:${minutes}:${seconds} ${amPm}`;
        return `${yyyy}/${MM}/${dd} ${time}`;
    }

    private PadTime(num: number): string {
        if (num > 9) {
            return num.toString();
        }
        return `0${num}`;
    }
}
